.ico {
    background: var(var(--icon-color--icon-primary));
    -webkit-mask-size: contain !important;
        mask-size: contain !important;
    -webkit-mask-position: center !important;
        mask-position: center !important;
    -webkit-mask-repeat: no-repeat !important;
        mask-repeat: no-repeat !important;
    --ico-2d-3d-image: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83404c21b947983d7cbd1_2d-3d-image.svg);
    --ico-aerospace: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834043eded056d7ee6c2a_aerospace.svg);
    --ico-ai-ml: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83405e6a579d762a2cecd_ai-ml.svg);
    --ico-audio-solid: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83404250bc1a5892dc669_audio-solid.svg);
    --ico-audio: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83404881bfa19b4d6de06_audio.svg);
    --ico-audit: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8340480b8bc6bf40f79f6_audit.svg);
    --ico-augmentation: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834042aefe9728e1e0d9d_augmentation.svg);
    --ico-authentication: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8340479b55f2a975a6a4e_authentication.svg);
    --ico-automotive: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83404e6aae193ddff2aad_automotive.svg);
    --ico-blog: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834042a8ec853a589f196_blog.svg);
    --ico-bullhorn: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83405428db5f2de961b7d_bullhorn.svg);
    --ico-case-study: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83405ebbd53419a011590_case-study.svg);
    --ico-checkmark-outline: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834059e36fac30adb93a5_checkmark-outline.svg);
    --ico-client-control-data: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834056d7b6b8c61174110_client-control-data.svg);
    --ico-close-outline: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8340593a2e8fbba869c0e_close-ouline.svg);
    --ico-cloud-data: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83405a33b595d05fd7ca9_cloud-data.svg);
    --ico-code-generation: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83405fc1dcbcde2d7db2f_code-generation.svg);
    --ico-computer-vision: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834053f244914d14b59fd_computer-vision.svg);
    --ico-crowd-login: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/667c3610019d373c75f2a1fd_crowd-login.svg);
    --ico-crowd-join: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/667c3611f039fe55173c382b_crowd-join.svg);
    --ico-crowd: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834054643b9f6191dcf84_crowd.svg);
    --ico-curate-data: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b7dcfafd4c796f048b4ee0_curate-data.svg);
    --ico-data-annotation: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83405bb2671c7931a4d3a_data-annotation.svg);
    --ico-data-collection: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83405cdca80d8070a720a_data-collection.svg);
    --ico-data-sheets: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834052a8ec853a589f25c_data-sheets.svg);
    --ico-data-types: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8340530fa9d5b63c38c55_data-types.svg);
    --ico-data-validation: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83405e9c987ba38cb5e2d_data-validation.svg);
    --ico-delivery: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834051779eefdb3bff85c_delivery.svg);
    --ico-document: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8340573229b69531abfca_document.svg);
    --ico-earth: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83411c21b947983d7d196_earth.svg);
    --ico-ebook: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83413eca909673d90577f_ebook.svg);
    --ico-encryption: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834116dc3b1f426591bb6_encryption.svg);
    --ico-entertainment: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834118cf9630f5d530e8c_entertainment.svg);
    --ico-file-audio: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83411b73727dafc79e150_file-audio.svg);
    --ico-file-security: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834117fcecd8c64636099_file-security.svg);
    --ico-file-video: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83413eca909673d905785_file-video.svg);
    --ico-finance: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834114643b9f6191dd7d4_finance.svg);
    --ico-fine-tune: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83411317123a6acc4925e_fine-tune.svg);
    --ico-gen-ai: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834127b51d881bd9c9530_gen-ai.svg);
    --ico-global-radar: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834112da8a2439d1ae615_global-radar.svg);
    --ico-global: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83411e9c987ba38cb6332_global.svg);
    --ico-group-people: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834111b9114446fd1fb28_group-people.svg);
    --ico-healthcare-alt: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834116d7b6b8c6117455e_healthcare-alt.svg);
    --ico-healthcare: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341112709ae44f2382cd_healthcare.svg);
    --ico-image-arrow: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341146cabf7e0f27ad97_image-arrow.svg);
    --ico-image-search: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b834121413d5b8afc13f41_image-search.svg);
    --ico-image-video: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83411903a11976e6eab8e_image-video.svg);
    --ico-image: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341296101a5362588f70_image.svg);
    --ico-industries: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83412f9e58815493c9420_industries.svg);
    --ico-layers: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b7d03dcf022cda795feb72_layers.svg);
    --ico-link: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/667c3610ac4c1c2f449ccf0c_link.svg);
    --ico-locked: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83412cdca80d8070a7745_locked.svg);
    --ico-magic-wand: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83412b19fc5081558ec90_magic-wand.svg);
    --ico-mobile-audio: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341248853d22f374973c_mobile-audio.svg);
    --ico-mobile-location: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83412b17894c5364828ea_mobile-location.svg);
    --ico-monitor: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341286713f229473626f_monitor.svg);
    --ico-on-premises: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83412f9e58815493c9475_on-premises.svg);
    --ico-point-of-interest: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341248853d22f374974a_point-of-interest.svg);
    --ico-policy: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b83412fc1dcbcde2d7e05e_policy.svg);
    --ico-precision: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341eed9a61ac4c5411f3_precision.svg);
    --ico-presentation: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e3d872a0f99c01948_presentation.svg);
    --ico-press: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e0881b365b99f894e_press.svg);
    --ico-retail: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e8f85e44f7ae4264e_retail.svg);
    --ico-robot: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e428db5f2de962afc_robot.svg);
    --ico-script: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341ee6aae193ddff3881_script.svg);
    --ico-secure-data: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341edb256e4ef92d026f_secure-data.svg);
    --ico-security: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e26e216df6cebed75_security.svg);
    --ico-services: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e9e36fac30adba4fe_services.svg);
    --ico-smiley: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e7fcecd8c646368b3_smiley.svg);
    --ico-speech-audio: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e428db5f2de962b18_speech-audio.svg);
    --ico-speech-modeling: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341ec21b947983d7d5e4_speech-modeling.svg);
    --ico-speech-to-text: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e1413d5b8afc14476_speech-to-text.svg);
    --ico-technology: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b7f9ca19f869a3e3b6b970_technology.svg);
    --ico-telecom: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e90596ba8e5d92331_telecom.svg);
    --ico-text-creation: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341ebcbef2061331b080_text-creation.svg);
    --ico-transcription: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e751553ed0974d008_transcription.svg);
    --ico-translation: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e751553ed0974d005_translation.svg);
    --ico-user: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/667c36113b45349d680caf58_user.svg);
    --ico-video: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e30fa9d5b63c3993d_video.svg);
    --ico-visual-recognition: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341e5ebce710fc6343b5_visual-recognition.svg);
    --ico-voice: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341ecf86c236d2b4f13d_voice.svg);
    --ico-webinar: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65b8341ecf86c236d2b4f161_webinar.svg);
    --ico-experience-oo: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65d229239653c54bca0b2450_experience-oo.svg);
    --ico-platform-oo: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65d229236104cd373d2f98d9_platform-oo.svg);
    --ico-people-oo: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65d2292343a1dbe0f9f476c9_people-oo.svg);
    --ico-chip: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65d51e078317049006309d53_chip.svg);
    --ico-data-vis: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/65d51e0b37643be911a3eb63_data-vis.svg);
    --ico-ar-vr: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/66bf7608fea10335bf8f3c04_augmented-reality.svg);
    --ico-red-teaming: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/66bf7608b5e5b4c2eebd39f5_red-teaming.svg);
    --ico-gauge: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/66bf76088c69994ed8dc4d49_gauge.svg);
    --ico-network: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/66bf760858345cff9977bde8_network.svg);
    --ico-data-labeling: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/66bf7608b5e5b4c2eebd39f1_data-labeling.svg);
    --ico-integration: url(https://uploads-ssl.webflow.com/656a605609413dfd3feb9d34/66bf760898ff51f5d3b5174a_integration.svg);
    --ico-customer-support: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/66d88a27c56c5784a0989938_customer-support.svg);
    --ico-design: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/66d88a2a51cefc283566ad9c_design.svg);
    --ico-bullet: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/66f4352b5e8038ef52df4575_bullet.svg);
    --ico-cost: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/66f5b5a513429302716d956c_cost.svg);
    --ico-time: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/66f5b5a8f50d090e6e6247d5_time.svg);
    --ico-logistics: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/66fd755d4f6a21f4b3b2def2_logistics.svg);
    --ico-urban-planning: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/66fd755d6c3039720fd623c1_urban-planning.svg);
    --ico-poi-data: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/66fd755df163aa2b95b8bd2b_poi-data.svg);
    --ico-auto-multi: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f9688fc828dabbab8959b_auto-multi.svg);
    --ico-collab: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f968876fd3de338f4a7d7_collaborate.svg);
    --ico-datacenter: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f9688a68e36e649140dfa_data-center.svg);
    --ico-data: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f9688432b816a078d2080_data.svg);
    --ico-image-to-text: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f9688df0e9dda526a6049_image-to-text.svg);
    --ico-search: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f968876e5c081375fac6c_search.svg);
    --ico-sme: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f9688ccf45bca6e668622_sme.svg);
    --ico-text-to-audio: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f9688e0c96f6d14c62ce5_text-to-audio.svg);
    --ico-text-to-image: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f96888796c72e73ee9400_text-to-image.svg);
    --ico-text-to-video: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f96889151708cb2836e62_text-to-video.svg);
    --ico-user-pref: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f9688f337f22772ad0c8d_user-preference.svg);
    --ico-video-to-text: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/673f96887d14178e8d0a8f6d_video-to-text.svg);
    --ico-clean: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/675c9de9e6b4353f641b8dd9_clean.svg);
    --ico-cognitive: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/675c9de74fd08e2f31af1d34_cognitive.svg);
    --ico-data-storage: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/675c9de7a9f03434dd4280d1_data-storage.svg);
    --ico-equalizer: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/675c9de7456e7fb08996c2cf_equalizer.svg);
    --ico-integrate: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/675c9de7047622b5c44ce1d5_integrate.svg);
    --ico-money: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/675c9de7067e70048ebcf99f_money.svg);
    --ico-syntax: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/675c9de7a0dfd0cf9437a53f_syntax.svg);
    --ico-vehicle-connected: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/675c9de848135f46ea504fab_vehicle-connected.svg);
    --ico-ehicle-insights: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/675c9de73d37d14ef9b3c9d6_vehicle-insights.svg);
    --ico-ab-test: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddadee03e388003b8b1_ab-test.svg);
    --ico-ab-test-alt: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddaed73d41126112669_ab-test-alt.svg);
    --ico-benchmark: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddaf76cc545fefddfd7_benchmark.svg);
    --ico-data-alt: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddac8b2598c2912a3c6_data-alt.svg);
    --ico-experiment: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddac0a62937ce18683b_experiment.svg);
    --ico-global-alt: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1dda4fe7f3cb23bb0d62_global-alt.svg);
    --ico-human-checked: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddaf3f0f13662824248_human-checked.svg);
    --ico-passes: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddaf57af28dcecc913f_passes.svg);
    --ico-passes-alt: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1dda371a1180d5e5a8b0_passes-alt.svg);
    --ico-processed: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddac5b8d9a83fb9b785_processed.svg);
    --ico-prompt-creation: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddaa7007dabdb0ef508_prompt-creation.svg);
    --ico-rating: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddbd61efe3593b6dd81_rating.svg);
    --ico-strong: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddaea2c2f229552d756_strong.svg);
    --ico-text-annotation: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1dda74afdd19cfcd9462_text-annotation.svg);
    --ico-tools: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ea181fcb5f0b746bb97_tools.svg);
    --ico-touch: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddbd61efe3593b6dd99_touch.svg);
    --ico-unbiased: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddaf82f90516228db8c_unbiased.svg);
    --ico-unified: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddbd61efe3593b6dd84_unified.svg);
    --ico-user-happy: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67ae1ddaf57af28dcecc91c5_user-happy.svg);
    --ico-human-assessment: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67af5d511a16859e792b5d66_human-assess.svg);
    --ico-contextual-failure: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67af5d55425018bb08193934_contextual-failure.svg);
    --ico-false-confidence: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67af5d5838bae48e91c9eba0_false-confidence.svg);
    --ico-vital-signs: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67afae3ea4f74d69c45b3980_vital-signs.svg);
    --ico-thermometer: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67afae3be485565759f722ec_thermometer.svg);
    --ico-scan: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67afae3839cf14b1ed44c007_scan.svg);
    --ico-ouput: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67b781c2680bb629c8c31432_output.svg);
    --ico-harmful-output: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67b781c29a815f6270ac3d65_harmful-output.svg);
    --ico-bias: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67b781c253e9a92ff366613a_bias.svg);
    --ico-flag: url(https://cdn.prod.website-files.com/656a605609413dfd3feb9d34/67b781c2c2496ff31700a45b_flag.svg);
}